<template>
  <div class="early-warning-wrapper">
    <div class="content-wrapper">
      <div class="card-box msg-list-header">
        <h4 class="title">
          <div class="warning-msg"></div>
          消息列表
        </h4>
        <div class="list-info">
          <!-- <span class="border-right-1px">未读<span class="no-read">{{noRead}}</span></p> -->
          <p>共计<span class="total">{{total}}</span>个</p>
        </div>
      </div>
      <div class="card-box list-wrapper">
        <scroll-list
          :page="page"
          :pageSize="pageSize"
          :total="total"
          :data="data"
          :getData="getData">
          <div class="msg-item-wrapper border-bottom-1px" v-for="item in data" :key="item.idAlarmNotice" @click="toDetail(item)">
            <h4 class="van-ellipsis title ignore" :class="{'no-read': !item.readFlag}">{{item.topic}}</h4>
            <div class="label">
              <p>发布人：{{item.createGroupName}}</p>
              <p>发布时间：{{item.publishTime}}</p>
            </div>
          </div>
        </scroll-list>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
const { getAlarmList } = API

export default {
  name: 'earlyWarning',
  data () {
    return {
      noRead: 1,
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10
    }
  },
  methods: {
    // 获取预警预告列表
    async getData (isRefresh) {
      if (isRefresh) {
        this.page = 1
      }

      let res = await getAlarmList({
        start: this.page,
        limit: this.pageSize
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.totalCount
        } else {
          throw new Error(res.message)
        }
      }

      this.data = isRefresh ? res.data : this.data.concat(res.data)
      this.page++
    },
    toDetail (data) {
      this.$router.push({
        name: 'warnDetail',
        params: {
          id: data.idAlarmNotice
        }
      })
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue')
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .early-warning-wrapper{
    .content-wrapper{
      .msg-list-header{
        @include flexCenter(space-between);
        padding: 20px 28px;
        .title{
          @include flexCenter(space-between);
          color: #333;
          font-size: 30px;
          .warning-msg{
            @include bgImage('../../assets/warn_msg', 64px, 64px);
            margin-right: 16px;
          }
        }
        .list-info{
          // @include flexCenter(space-between);
          color: #999;
          font-size: 28px;
          // p:first-child{
          //   padding-right: 36px;
          //   margin-right: 36px;
          // }
          span{
            margin: 0 16px;
            font-size: 28px;
            &.no-read{
              color: #FF5757;
            }
            &.total{
              color: #333;
            }
          }
        }
      }
      .list-wrapper{
        position: absolute;
        top: 148px;
        left: 24px;
        right: 24px;
        bottom: 20px;
        overflow: auto;
        .msg-item-wrapper{
          &:not(:last-child) {
            padding-bottom: 32px;
            margin-bottom: 32px;
          }
          .title.ignore{
            color: #333;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 14px;
            line-height: 1.5;
            &.no-read::before{
              content: '';
              @include circle(12px);
              display: inline-block;
              // 在安卓手机中rem单位border-radius:50%画圆变形的解决方案
              transform: scale(.5);
              transform-origin: 0% center;
              background: #FF5757;
            }
          }
          .label{
            color: #999;
            font-size: 28px;
            p:first-child{
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
</style>
